import { useEffect } from 'react'

export default function useLayoutEffects(location) {
    useEffect(() => {
        //
        // const isDark = !('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches
        //
        // if (localStorage.theme === 'dark' || isDark) {
        //     document.documentElement.classList.add('dark')
        // } else {
        //     document.documentElement.classList.remove('dark')
        // }
        document.documentElement.classList.remove('dark')

        setTimeout(() => {
            window.scroll({
                top: -1,
                left: 0,
                behavior: 'smooth',
            })
        }, 1)
    }, [location])
}
